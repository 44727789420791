/* common */
table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}
table td,
table th {
  border-bottom: 1px #c4c4c4 solid;
  border-collapse: collapse;
  padding: 16px;
  border-spacing: 0;
}
table th {
  text-align: left;
}
/* table td:last-child {border-bottom: none;} */

.indent {
  padding: 0 0 0 35px;
  margin: -16px -16px -17px;
  border-bottom: 2px black solid;
}

.catHeader {
  background-color: #7b88a8;
}

/* Tool Action Table */
.inner-table {
  margin: -6px -16px -6px -16px;
  padding-left: 35px;
}

.main-table .toolActionHeader th {
  background-color: #e9e9e9;
}
.main-table .toolActionHeader th.gridicon,
.main-table .toolActionHeader td.gridicon {
  width: 50px;
  text-align: center;
}

.tooltitle {
  white-space: nowrap;
}

/* Material Overrides */
.MuiAccordion-rounded:last-child,
.MuiAccordion-rounded:last-child {
  border-radius: 0 !important;
  box-shadow: none !important;
}

.toolActionHeader .MuiAccordionSummary-root {
  padding-left: 0;
}
.toolActionHeader .MuiAccordionSummary-root.Mui-expanded {
  min-height: 48px;
}
.toolActionHeader .MuiAccordionSummary-content.Mui-expanded {
  margin: 12px 0;
}
.toolActionHeader .MuiAccordionDetails-root {
  background: white;
  padding: 0;
}

.toolActionHeader .MuiAccordionDetails-root table th {
  background-color: #f4f4f4;
}
.toolActionHeader .MuiAccordionDetails-root table tr:last-child td {
  border-bottom: none;
}

.MuiAccordionSummary-content {
  font-weight: bold;
  color: #7b88a8;
}
.MuiAccordionSummary-content:before {
  border: 2px #7b88a8 solid;
  content: '';
  width: 6px;
  height: 6px;
  display: inline-block;
  margin-right: 10px;
  transform: rotate(45deg);
  align-self: center;
  border-width: 2px 2px 0px 0;
  position: absolute;
  left: -20px;
}
.toolActionHeader .MuiAccordionSummary-content.Mui-expanded:before {
  transform: rotate(135deg);
}

.main-table th {
  background-color: 'white';
  padding-bottom: 12px;
  padding-top: 12px;
  font-weight: 700;
  /* border-bottom: 4px #2196f3 solid; */
  box-shadow: 0 1px 0 #bdbdbd;
  top: -8px !important;
}

.simple-form {
  padding: 20px;
  text-align: left;
}
.simple-form .MuiCardContent-root {
  padding: 0;
}
.ra-input h2 {
  width: auto;
  margin: 0 0 20px 10px;
  text-align: left;
  padding-bottom: 20px;
  border-bottom: 1px #ccc solid;
}

.form-row {
  display: flex;
  width: auto !important;
}

.form-row .MuiFilledInput-root {
  display: block;
  flex: 1;
  border-radius: 0;
  background-color: transparent;
}

/* .flex {
  border: 1px red solid;
} */

.ra-input {
  width: auto;
}
.MuiSelect-selectMenu {
  text-align: left;
}
.form-row .MuiFormControl-marginDense {
  flex: 1;
  margin-left: 10px;
  margin-right: 10px;
}

.MuiFilledInput-input {
  padding-left: 2px !important;
}
.form-row .MuiInputLabel-filled.MuiInputLabel-shrink.MuiInputLabel-marginDense,
.group .MuiInputLabel-filled.MuiInputLabel-shrink.MuiInputLabel-marginDense,
.questions-list
  .MuiInputLabel-filled.MuiInputLabel-shrink.MuiInputLabel-marginDense {
  transform: translate(0px, 7px) scale(0.75);
}

.children {
  width: auto !important;
}
.children .MuiFormControl-root {
  width: 100%;
  margin: 0 5px;
}
/* .children ul { border: 1px red solid;}*/
.children ul li:last-child {
  padding: 15px 0;
}
.children ul li:last-child span {
  padding: 0;
}

/* .children section { margin-bottom: 20px;} */
.children .group {
  width: 100%;
  display: flex;
  border-bottom: 1px #ccc solid;
  margin-bottom: 20px;
  padding-top: 20px;
}

.children .group li ul li {
  width: 100%;
  display: flex;
  border-bottom: 1px blue solid;
  margin-bottom: 20px;
}

.children .MuiTypography-body1 {
  position: relative;
  padding-top: 32px;
}
.children .MuiTypography-body1:before {
  content: '';
  position: absolute;
  left: 50%;
  height: calc(100% - 90px);
  width: 1px;
  border-left: 2px dotted #ccc;
  top: 70px;
  margin-left: -1px;
}

/* .children div[class^="RaFormInput"] { background: red; width: auto !important;} */

.children .questions-list li {
  padding: 25px 0;
}
.children .questions-list li:last-child {
  padding: 25px 0;
}
.children .questions-list li section {
  margin-bottom: -25px;
}
.children .questions-list li:last-child span {
  padding-top: 0 !important;
}

section + span {
  margin-left: 10px;
  padding-top: 15px !important;
}

/* Users  */
.searchWrapper {
}
.searchWrapper .MuiInputBase-root {
  width: 320px;
  background: transparent;
}
.searchWrapper .MuiInputBase-root:hover,
.searchWrapper .MuiInputBase-root.Mui-focused {
  background: transparent;
}

/* Layout */

.MuiDrawer-root {
  margin-right: 24px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1) inset;
}

.MuiMenuItem-root {
  padding: 16px !important;
  border-bottom: 1px rgba(0, 0, 0, 0.1) solid !important;
}

@media (min-width: 600px) {
  .MuiMenuItem-root {
    padding: 16px;
  }
}
.MuiMenuItem-root {
  min-height: 48px;
}

.RaMenuItemLink-active-31,
.RaMenuItemLink-active-31 .MuiListItemIcon-root {
  color: black !important;
  font-weight: 500 !important;
}

/* EDit Audit */
.editAudit {
  padding: 10px;
  text-align: left;
}
.editAudit .MuiCardContent-root {
  display: flex;
  align-items: flex-start;
}

.editAudit .MuiFormControl-root {
  margin: 0;
}
.editAudit .RaLabeled-value-40 {
  padding: 0;
}
.editAudit .firstValue {
  padding: 10px 10px 10px 0;
}

.editAudit .firstValue label {
  transform: translate(0px, -5px) scale(0.75);
}

.p10 {
  padding: 10px;
}
.editAudit .toolHead {
  font-weight: bold;
  border-bottom: 1px #ccc solid;
  padding-bottom: 8px;
  margin-bottom: 8px;
  min-width: 300px;
}
.rowWrap {
  padding: 0 0 10px 0;
  border-bottom: 1px #ccc solid;
  margin-bottom: 10px;
}
.has-flex {
  display: flex;
}
.is-flex {
  flex: 1;
}
.w50p {
  width: 50%;
}
.selectWrapper {
  margin-top: 10px !important;
}

.createQuestion {
  padding: 0px 30px 25px;
}
.createQuestion .ra-input {
  padding-right: 20px;
}
.createQuestion .MuiCardContent-root {
  display: flex;
}
.createQuestion .MuiTextField-root {
  width: 100%;
  margin: 12px 0;
}

.createQuestion .questionType {
  width: 500px;
}

.questionType .MuiFormControl-marginNormal {
  margin-top: 0;
}

.questionType .MuiFormHelperText-root {
  display: none;
}

.createQuestion
  .MuiInputLabel-filled.MuiInputLabel-shrink.MuiInputLabel-marginDense {
  transform: translate(0px, 5px) scale(0.75);
}

.thumbNailView img {
  height: 30px;
  width: 30px;
  border-radius: 50%;
}
/* .main-table td{ padding:0;} */
/* .main-table td span{ padding: 16px; display: inline-block} */
/* .MuiTableCell-sizeSmall { padding: 0 !important;} */

/* Added by Prashanth */

.styled-form2 .MuiCardContent-root {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  padding-top: 0 !important;
}
.text-display {
  padding-top: 20px !important;
}

.intake-wrapper {
  max-height: 100vh;
  box-sizing: border-box;
  overflow: auto;
}

.intake-form {
  display: grid;
  grid-template-columns: 1fr;
  /* grid-gap: 1rem; */
  /* border:1px red solid; */
  width: auto !important;
}

.MuiFormControl-root label {
  position: static;
  transform: none !important;
  margin-bottom: 10px;
  line-height: 22px;
}

.MuiFilledInput-input {
  padding: 15px !important;
}
.MuiFormControl-root .MuiInputBase-root {
  padding: 0;
  border: 1px #ccc solid;
  background-color: transparent;
  border-radius: 4px;
}
.MuiFormControl-root .MuiInputBase-root.Mui-disabled::after,
.MuiFormControl-root .MuiInputBase-root.Mui-disabled::before {
  display: none;
}
.intake-width .MuiCardContent-root {
  padding-top: 0 !important;
}
.intake-width .MuiFilledInput-underline:before {
  border-bottom: none;
}
.intake-width {
  width: 60vw;
  box-sizing: border-box;
}
.intake-width .simple-form {
  height: 100vh;
  overflow: auto;
  box-sizing: border-box;
  padding: 30px 30px 60px;
}
.intake-width .MuiToolbar-root {
  position: absolute;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  background: #ccc;
  z-index: 1;
  box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.1);
  border-top: 1px white solid;
}

.text-indent .MuiSelect-root span {
  padding-left: 10px;
}

.note {
  padding: 30px;
}
.note div {
  margin-bottom: 20px;
}
.note h2 {
  margin: 0 0 30px;
}
.note h3 {
  margin: 0 0 10px;
}

.grid-3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1rem;
  width: 100% !important;
  align-items: flex-end;
}

.sub-headers {
  margin-bottom: 10px;
  margin-top: 20px;
}

@media (min-width: 0px) {
  .RaLayout-appFrame-2,
  .RaLayout-appFrame-17 {
    margin-top: 70px !important;
  }

  .layout > div {
    margin-top: 70px !important;
  }
}
.RaLayout-contentWithSidebar-3,
.RaLayout-content-4,
[class^='RaLayout-content-'] {
  height: calc(100vh - 70px);
  overflow-y: auto;
  /* border: 1px red solid; */
}
/* .RaDatagrid-headerCell-83 { 
  top:-8px;
} */

.force-scroll {
  overflow: unset !important;
}

th.MuiTableCell-head {
  background-color: #f1f1f1;
  border-bottom-color: #bdbdbd;
}
.MuiTableCell-sizeSmall {
  padding: 10px 24px 10px 16px !important;
}

/* Text box styling */

.border-customized-input {
  border: 2px solid #eee;
  padding: 8px;
  border-radius: 6px;
}

.MuiFilledInput-multiline.MuiFilledInput-marginDense {
  padding-top: 0 !important;
}

@media (min-width: 960px) {
  .RaMenu-main-56 {
    margin-top: 0px !important;
  }
}

.right-panel .guest-details-wrap {
  display: flex !important;
  flex-wrap: wrap;
  align-items: flex-start !important;
}

.right-panel .guest-details-wrap .guest-details-fields {
  display: grid !important;
  align-items: flex-start !important;
}

.right-panel .guest-details-wrap .label {
  padding: 2px 5px !important;
  font-weight: 500 !important;
}

.right-panel .guest-details-wrap > div {
  flex: 1;
}
.w-0-imp {
  width: -1 !important;
}
.filters-flex {
  display: flex;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1rem;
  width: 100% !important;
}
.filters-form {
  display: flex;
}
.filters-form.RaToolbar-desktopToolbar-66 {
  margin-top: 50px !important;
}
.filters-form.RaToolbar-toolbar-65 {
  background-color: none !important;
}

/* danger color */
.danger-color{
  color: red;
}