.details-display,
.right-panel .ra-field > div {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
}
.inline-block-imp {
  display: inline-block !important;
}
.right-panel .ra-field > div {
  align-items: center;
}

.details-display.align-center > div {
  align-items: center;
}

.right-panel {
  padding: 20px;
}

.right-panel .ra-field {
  margin: 0px 0px;
}

.label {
  background-color: rgba(0, 0, 0, 0.04);
  padding: 10px;
}

.blueLink {
  color: #2196f3;
  cursor: pointer;
}

header.MuiAppBar-root {
  box-shadow: none;
  border-bottom: 1px #ccc solid;
}

header.MuiAppBar-root.MuiAppBar-colorSecondary {
  background-color: #8dd1d8 !important;
}

.MuiCardContent-root:first-child {
  padding-top: 0 !important;
}
